
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { VuetifyMixin, StringsMixin, BAIconsMixin, RoutingMixin } from '@/mixins';
import { BAPartnerModel, BAPartnerProduct, BAPartnerProductPrice } from "@/models";
import { notificationStore, baPartnersStore } from '@/store'
import { DataTableHeader } from 'vuetify';
import CopyToClipboard from '@/components/ui/CopyToClipboard.vue';
import { baPartnerApi } from '@/api/BAPartnerApi';

@Component({
	components: {
		Page,
	}
})
export default class PartnerAdminDashboard extends Mixins(VuetifyMixin, StringsMixin, BAIconsMixin, RoutingMixin){
    loadingPartners: boolean = true;
    partners: Array<BAPartnerModel>;
    search = "";
    
    mounted() {
        this.loadPartners();
    }
    async loadPartners() {
        this.loadingPartners = true;
        try {
            await baPartnersStore.loadPartners();
            this.partners = baPartnersStore.partners;
        } catch(e) {
            notificationStore.snackbarShowError(`Error loading partners: ${e}`);
        }
        this.loadingPartners = false;
    }
    get IsLoading(): boolean {
        return this.loadingPartners || baPartnersStore.loadingPartners || this.editingPartner;
    }

    async GetNewUUID(): Promise<string> {
        const { uuid } = await baPartnerApi.uuid();
        return uuid;
    }
    async private RestoreBackup(a: Array<any>, backup: any): Promise<number> {
        if( this.IsEmpty(backup) ) return -1;
        const i = a.findIndex(item => item.id === backup.id);
        a[i] = backup.deepcopy();
        return i;
    }

    get PartnerHeaders(): DataTableHeader<any>[] {
        return [
            { text: 'Icon', value: 'icon' },
            { text: 'Name', value: 'name' },
            { text: 'Website', value: 'website'},
            { text: 'Products', value: 'NumProducts' },
            { text: 'ID', value: 'id'},
            { text: '', width:"50", value: 'actions', sortable: false },
            { text: '', width:"25", value: 'data-table-expand' }
        ]
    }

    changesPending: boolean = false;
    editTitle: string = "Edit Partner";
    backupPartner: BAPartnerModel;
    editPartner: BAPartnerModel = new BAPartnerModel;
    validPartner: boolean;
    editingPartner: boolean = false;
    async onAddPartner() {
        this.editTitle = "Add Partner";
        this.backupPartner = undefined;
        this.editPartner = new BAPartnerModel();
        this.editPartner.id = await this.GetNewUUID();
        this.editingPartner = true;
    }
    async onEditPartner(partner: BAPartnerModel) {
        this.editTitle = `Edit Partner: ${partner.name}`;
        this.backupPartner = partner.deepcopy();
        this.editPartner = partner;
        this.editingPartner = true;
    }
    async onDeletePartner(partner: BAPartnerModel) {
        if( !confirm(`Delete ${partner.name}? This action cannot be undone`) ) return;

        await baPartnersStore.deletePartner(partner);
        await this.loadPartners();
    }
    async onCancelEditDlg() {
        await this.RestoreBackup(this.partners, this.backupPartner);
        this.editingPartner = false;
    }
    async onSaveEditPartner(keepOpen: boolean = false) {
        this.showProducts = false;
        await baPartnersStore.savePartner(this.editPartner);
        await this.loadPartners();
        this.editingPartner = keepOpen;
        if( keepOpen ) this.editPartner = this.partners.find(p => p.id === this.editPartner.id);
        this.changesPending = false;
        this.showProducts = true;
    }

    get ProductHeaders(): Array<DataTableHeader<any>> {
        return [
            { text: 'Name', value: 'name' },
            { text: 'Prices', value: 'prices' },
            { text: 'Stripe ID', value: 'stripeId' },
            { text: '', width:"50", value: 'actions', sortable: false },
            { text: '', width:"25", value: 'data-table-expand' }
        ]
    }
    showProducts: boolean = false;
    editProductTitle: string;
    editProduct: BAPartnerProduct = new BAPartnerProduct;
    editingProduct: boolean = false;
    backupProduct: BAPartnerProduct;
    validProduct: boolean;
    productAlert: string = "";
    IsAddingNewProduct(): boolean {
        return this.editingProduct && this.IsEmpty(this.backupProduct);
    }
    async onAddProduct() {
        this.editProductTitle = "Add Product";
        this.backupProduct = undefined;
        this.editProduct = new BAPartnerProduct();
        this.editProduct.id = await this.GetNewUUID();
        this.editingProduct = true;
    }
    async onEditProduct(product: BAPartnerProduct) {
        this.editProductTitle = `Edit Product: ${product.name}`;
        this.backupProduct = product.deepcopy();
        this.editProduct = product;
        this.editingProduct = true;
    }
    async onDeleteProduct(product: BAPartnerProduct) {
        if( !confirm(`Delete ${product.name}? This action cannot be undone`) ) return;

        this.editingProduct = false;
        this.editPartner.products = this.editPartner.products.filter(p => p.stripeId != product.stripeId );
    }
    async onCancelEditProduct() {
        await this.RestoreBackup(this.editPartner.products, this.backupProduct);
        this.editingProduct = false;
    }
    get HasProductAlert(): boolean {
        return this.IsNotEmpty(this.productAlert);
    }
    async onSaveEditProduct() {
        if( !this.editProduct.HasPrices ) {
            this.productAlert = "Products require at least one price"
            return;
        }
        if( this.IsEmpty(this.editProduct.name) ) {
            this.productAlert = "Please provide a product name"
            return;
        }
        if( this.IsEmpty(this.editProduct.buttonAction) ) {
            this.productAlert = "Please provide text for the action button"
            return;
        }

        if( !this.editPartner.products.includes(this.editProduct) ) this.editPartner.products.push(this.editProduct);
        this.changesPending = true;
        this.editingProduct = false;
    }
    async onAddProductPrice() {
        const newPrice = new BAPartnerProductPrice();
        newPrice.id = await this.GetNewUUID();
        newPrice.name = "Strandard";
        newPrice.price = 999;
        this.productAlert = "";
        this.editProduct.prices.push(newPrice);
    }
    async onDeletePrice(price: BAPartnerProductPrice) {
        price.deleted = true;
        if( this.IsEmpty(price.stripeId) ) {
            this.editProduct.prices = this.editProduct.prices.filter(p => p.id === price.id);
        }
    }

    newDataLabel: string = '';
    onRemoveAllDataLabels() {
        this.editProduct.dataLabels = [];
    }
    onRemoveDataLabel(index: number) {
        this.editProduct.dataLabels.splice(index, 1);
    }
    onNewDataLabel() {
        if( this.IsEmpty(this.newDataLabel) ) return;
        if( this.editProduct.dataLabels.includes(this.newDataLabel) ) return;

        const commentsIndex = this.editProduct.dataLabels.indexOf('Comments');
        if( commentsIndex > 0 ) {
            this.editProduct.dataLabels.splice(commentsIndex, 0, this.newDataLabel);
        } else {
            this.editProduct.dataLabels.push(this.newDataLabel);
        }
    } 
}
